<template>
  <div>
    <CRow>
      <CCol class="col-md-12">
        <template lang="html">
          <div>
            <!-- <vs-button @click="popupActive = true" color="primary" type="border"
              >Open Default popup</vs-button
            > -->
            <div>
              <CDropdownItem @click="popupActive = true"
                >Mandatory Or Not</CDropdownItem
              >
            </div>

            <div class="center">
              <!-- <slot /> -->
              <vs-popup
                :active.sync="popupActive"
                title="This Check is Mandatory ?"
                style="none"
                icon="hidden"
              >
                <CForm>
                  <slot />
                  <div>
                    <!-- <span class="col-md-2">
                      <input type="checkbox" class="mr-2" />Yes
                    </span>
                    <span class="col-md-2">
                      <input type="checkbox" class="mr-2" />No
                    </span> -->
                  </div>
                </CForm>
              </vs-popup>
              <!-- </div> -->
            </div>
          </div>
        </template>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import { vsPopup } from "vuesax";
import "vuesax/dist/vuesax.css";

Vue.use(vsPopup);

export default {
  name: "mergeUsers",
  data() {
    return {
      popupActive: false,
      items: [],
      description: "",
    };
  },
  methods: {
    cancel() {
      window.history.back();
    },
  },
};
// documnet.getElementByClassName("title").title = "hidden";
</script>

<style >
input {
  font-size: 19px;
}
/* .title {
  display: hidden;
} */
</style>