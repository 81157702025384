<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewCheck"
          >
            New Check
          </button>
        </div>

        <CDataTable
          :items="items"
          :fields="fields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          ref="vuetable"
          pagination
          :responsive="false"
        >
          <template #remarks-filter>
            <select
              class="form-control form-control-sm"
              @input="
                $refs.vuetable.columnFilterEvent(
                  'remarks',
                  $event.target.value,
                  'input'
                )
              "
            >
              <option value selected="selected">Any</option>
              <option value="0">Numbers Only</option>
              <option value="1">Need Date</option>
              <option value="2">Need Date Time</option>
              <option value="3">Free Text</option>
            </select>
          </template>
          <template #inputTypeDecimal-filter>
            <select
              class="form-control form-control-sm"
              @input="
                $refs.vuetable.columnFilterEvent(
                  'inputTypeDecimal',
                  $event.target.value,
                  'input'
                )
              "
            >
              <!-- 
            <select
              name="LeaveType"
              @change="onChange($event)"
              class="form-control"
            > -->
              <option value selected="selected">Any</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </template>
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #checkDescription="{ item }">
            <td class="font-weight-bold" v-if="item.checkDescription == null">
              --
            </td>
            <td class="font-weight-bold" v-else>{{ item.checkDescription }}</td>
          </template>

          <template #remarks="{ item }">
            <!-- <input type="text" v-model="search" placeholder="search">
            <div v-for="item in filteredPeople"> -->
            <!-- <template slot-scope="{ filteredPeople }">
              <p v-for="item in filteredPeople" :key="item"> -->
            <!-- <select class="form-control">
              <option disabled value="">--Select One Option--</option>
              <option value="true">Numbers Only</option>
              <option value="false">Need Date</option>
              <option value="false">Need Date</option>
              <option value="false">Free Text</option>
            </select> -->
            <td class="font-weight-bold" v-show="item.remarks == 0">
              Numbers Only
            </td>
            <td class="font-weight-bold" v-show="item.remarks == 1">
              Need Date
            </td>
            <td class="font-weight-bold" v-show="item.remarks == 2">
              Need Date Time
            </td>
            <td class="font-weight-bold" v-show="item.remarks == 3">
              Free Text
            </td>
            <!-- </p>
            </template> -->
          </template>

          <template #needtoUpload="{ item }">
            <td class="font-weight-bold" v-if="item.status == 0">No</td>
            <td class="font-weight-bold" v-else>Yes</td>
          </template>
          <template #inputTypeDecimal="{ item }">
            <td class="font-weight-bold" v-if="item.inputTypeDecimal == 0">
              No
            </td>
            <td class="font-weight-bold" v-else>Yes</td>
          </template>
          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'Options',
                    params: { checkId: item.checkId, mode: 'view' },
                  }"
                  >Options</CDropdownItem
                >
                <CDropdownItem
                  :to="{
                    name: 'Check',
                    params: { checkId: item.checkId, mode: 'view' },
                  }"
                  >View</CDropdownItem
                >
                <div>
                  <CheckDescription>
                    <!-- <p v-if="item.subGroupName == 'QC' "> 
                      <span class="Merge Users"
                        > Are you sure you want to Merge YC PPT Department Users ?</span>
                      <p>
                         <p v-if="item.subGroupName == 'YC' "> 
                      <span class="Merge Users"
                        > Are you sure you want to Merge QC PPT Department Users ?</span>
                      <p> -->
                    <!-- <div class="col-sm-9"> -->

                    <select class="form-control" v-model="item.checkMandatory">
                      <!-- <option disabled value="">--Select One Option--</option> -->
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                    <br />

                    <!-- </div> -->

                    <!-- </p> -->

                    <!-- <span class="col-md-2" v-if="item.mergeDepartment == true">
                      <input id="yes" type="checkbox" class="mr-2" checked/> Yes
                       <input id="no" type="checkbox" class="mr-2"  @click="uncheckNo(item.mergeDepartment)" /> No
                    </span> -->
                    <!-- <span class="col-md-2" v-else>
                      <input
                      id = "yes2"
                        type="checkbox"
                        class="mr-2"
                        @click="uncheckNo(item.mergeDepartment)"
                      />Yes
                    </span> -->

                    <!-- <span class="col-md-2" v-if="item.mergeDepartment == false">
                       <input id="yes" type="checkbox" class="mr-2"  @click="uncheckYes(item.mergeDepartment)" /> Yes
                      <input id="no" type="checkbox" class="mr-2" checked />No
                    </span> -->

                    <!-- <span class="col-md-2" v-else>
                      <input id = "no2"
                        type="checkbox"
                        class="mr-2"
                       
                      />No
                    </span> -->
                    <!-- <div>
                      <br />
                    </div> -->
                    <div align="right">
                      <CButton
                        class="mr-2"
                        type="submit"
                        size="sm"
                        color="primary"
                        @click="
                          checkMandatory(item.checkId, item.checkMandatory)
                        "
                        >Save</CButton
                      >
                      <CButton
                        class="mr-2"
                        type="Cancel"
                        size="sm"
                        color="danger"
                        @click="previous()"
                        >Cancel</CButton
                      >
                    </div>
                  </CheckDescription>
                </div>

                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteFaq(item.checkId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
      <CCardFooter align="right">
        <CButton
          class="mr-2"
          type="Cancel"
          size="sm"
          color="danger"
          @click="cancel()"
        >
          <CIcon name="cil-ban" /> Cancel
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
const fields = [
  "index",
  {
    key: "checkDescription",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  { key: "remarks", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "inputTypeDecimal",
    label: "Need to Give Decimal",
    _classes: "font-weight-bold",
    _style: "min-width:100px",
  },
  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import ConfirmationModal from "../../containers/ConfirmationModal";
import CheckDescription from "../../containers/checkDescription.vue";
import Router from "vue-router";
export default {
  name: "Checks",
  components: {
    ConfirmationModal,
    CheckDescription,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  computed: {
    filteredPeople() {
      const { NumbersOnly, NeedDate, NeedDateTime, FreeText } = this;
      return this.item
        .filter(
          (item) =>
            item.NumbersOnly.toLowerCase().indexOf(NumbersOnly.toLowerCase()) >
            -1
        )
        .filter((item) => item.NeedDate === NeedDate);
    },
  },
  methods: {
    getChecks() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/checks/" +
          this.$route.params.itemId
      )
        .then((response) => response.json())
        .then(
          (data) => ((this.items = data), console.log("checks", this.items))
        );
    },
    checkMandatory(checkId, checkMandatory) {
      console.log("check mandatory called with", checkId, checkMandatory);

      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/check/mandatory/" +
          checkId +
          "?checkMandatory=" +
          checkMandatory
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("check description", data);

          window.location.reload();
        });
    },
    previous() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/checks/" +
          this.$route.params.itemId
      )
        .then((response) => response.json())
        .then(
          (data) => (
            (this.items = data),
            console.log("checks", this.items),
            window.location.reload()
          )
        );
    },

    cancel() {
      this.$router.push({
        name: "Items",
        params: { taskId: this.$route.params.taskId },
      });
    },

    //  movetrash(faqId) {
    //  this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/delete/faq/" + faqId, {
    //     method: "GET",
    //     // headers: this.defaultHeaders()
    //   }); console.log("delete called with", taskId);

    //  this.getSymptoms();
    //   this.$nextTick(() => {
    //     this.$refs.vuetable.refresh();
    //   });
    //   window.location.reload();
    // },
    deleteFaq(checkId) {
      console.log("delete called with", checkId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/check/" +
          checkId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getChecks();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          console.log(window.location.reload);
          window.location.reload();
        });
    },
    navToNewCheck() {
      this.$router.push({
        name: "Check",
        params: { itemId: this.$route.params.itemId, mode: "new" },
      });
    },
  },
  mounted() {
    this.getChecks();
  },
};
</script>